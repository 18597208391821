/* ====================================
// css variables
// ==================================== */

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* ====================================
// base rules
// ==================================== */

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  background-color: white;
  list-style-type: none;
}

a {
  color: #000;
  transition: 0.2s;
}

/* header */

header {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header li a {
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
  color: #0ED9DF;
}

.logo {
  width: 110px;
  padding: 3px 0 3px 20px;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}

/* SECTION */


section {
  padding-top: 60px;
}

.section-film {
  padding-top: 1px;
}

.product-carousel {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.product-carousel .product {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  
}
.product-carousel .product-top {
  width: 100%;
  
}

.product-carousel .product-image {
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 100%;
  
}

/** SLICK SLIDER CSS **/
/* Slider */
.slick-slider { 
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}
.slick-loading .slick-track {
  visibility: hidden;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  height: auto;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* ---> MEDIAQUERIES <--- */

.a1 {
  font-weight: 10;
  font-size: 100px;
  color: white;
  line-height: 100px;
  text-align: justify;
  text-transform: uppercase;
  margin-bottom: 100px;
}

.a2 {
  font-weight: 300;
  font-size: 100px;
  color: white;
  line-height: 100px;
  text-align: justify;
  text-transform: uppercase;
  margin-bottom: 100px;
}

  /* ---> films <--- */
  
  .articles-list {
    display: grid;
  }
  
  .col-view .articles-list {
    grid-template-columns: repeat(auto-fill, minmax(260px, auto));
  }
  
  @media screen and (min-width: 480px) {
    .list-view .article {
      display: grid;
      grid-template-columns: 200px auto;
      grid-template-areas: "article-img article-content";
    }
  
    .list-view .article:nth-child(even) {
      grid-template-columns: auto 200px;
      grid-template-areas: "article-content article-img";
    }
  }
  
  .affiche {
    width: 100%;
    margin: auto;
  }

  .affiche_about_us {
    width: 70%;
    margin: auto;
  }

  .texte {
    padding: 20px 0 50px 0;
    border-bottom: 1px solid #b3aca7;
    position: relative;
  }

  .texte-film {
    padding: 20px 0;
    border-bottom: 1px solid #b3aca7;
    display: flex;
    line-height: 30px;
    text-decoration: none;
  }

  .texte-film ul {
    padding: 0;
    
  }

  .TITRE {
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
  }

  @media screen and (max-width: 576px) {
    .TITRE {
      margin-top: 40px;
    }
  }

  h1 {
    font-size: 25px;
    color: #b3aca7;
    font-weight: 200;
}

h2 {
    text-align: center;
    font-size: 23px;
    color: black;
    font-weight: 100;
    margin: 0px 20px 20px 20px;
}

.container {
  margin: 90px 0;
}

li {
  list-style-type: none;
}

    /* ---> films <--- */

    .us {
      padding-left: 30px;
      padding-bottom: 40px;
    }

    .about {
      padding: 0 30px 30px 30px;
    }

    .about-2 {
      padding: 30px 0 0 0;
    }

    @media screen and (max-width: 576px) {
      .about {
        padding: 0 10px 10px 10px;
      }
  
      .about-2 {
        padding: 20px 0 0 0;
      }
    }



    .about-border {
      padding: 0 30px 60px 0px;
      margin: 0 30px 30px 30px;
      border-bottom: 1px solid #b3aca7;
    }

    .case-film {
      width: 40%;
    }

    /* CONTACT */

.contact {
  text-align: center;
  font-weight: 800;
  font-size: 50px;
  margin-top: 50px;
}

/* Placeholder styles */
::placeholder {
  color: black;
  font-size: 0.875em;
}

/* Placeholder styles on focus */
:focus::placeholder {
  color: black;
}

/* Placeholder styles on hover */
:hover::placeholder {
  color: #0ED9DF;
  font-size: 0.875em;
}

/* Form container styles */
#form {
  width: 50%;
  margin: 50px auto 100px auto;
}

@media screen and (max-width: 576px) {
  #form {
    width: auto;
    margin: 40px 20px 80px 20px;
  }
}

/* Input styles */
input, textarea {
  width: 100%;
  font-family: Helvetica, sans-serif;
  background: transparent;
  outline: none;
  color: black;
  border: 1px solid black;
  transition: all 0.3s ease-in-out;
  margin: 5px;
}

input {
  padding: 20px;
}

input:hover, textarea:hover {
  background: #f2f2f2;
  color: black;
  transition: all 0.3s ease-in-out;
}

/* Textarea styles */
textarea {
  height: 110px;
  max-height: 110px;
  padding: 15px;
}

/* Submit button styles */
#submit {
  font-size: 0.875em;
  color: black;
  outline: none;
  cursor: pointer;
  border: 1px solid black;
  border-top: 1px solid black;
}

#submit:hover {
  color: #0ED9DF;
}



/* FOOTER */
  
.footer {
  background-color: white;
  color: black;
  text-align: left;
  padding: 40px 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

.footer-left, .footer-center, .footer-right {
  margin: 10px;
}

.footer img {
  max-width: 100%;
  height: auto;
}

.footer-links {
  margin: 20px 0;
}

.footer-links a {
  color: black;
  text-decoration: none;
  margin-right: 10px;
  margin-left: 10px;
}

.footer-links a:hover {
  color: #0ED9DF;
}

.footer-company-name {
  color: #c2c2c2;
  font-size: 10px;
}

.footer-center div {
  margin: 10px 0;
}

.footer-center i, .footer-right i {
  color: black;
  margin: 5px;
  padding: 10px;
}

.footer-center p, .footer-right p {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.footer-center p span {
  display: block;
  font-size: 14px;
}

.footer-right .footer-company-about {
  font-size: 13px;
  color: #92999f;
}

.footer-icons a {
  color: black;
  text-decoration: none;
  margin-right: 10px;
}

.footer-icons i:hover {
  color: #0ED9DF;
  transition: 0.2s;
}

.footer-icons {
  font-size: 25px;
  padding-top: 20px;
}

.copyright {
  flex: 100%;
  color: #c2c2c2;
  font-size: 10px;
}

@media (max-width: 600px) {
  .footer {
    text-align: center;
  }
  .footer-left, .footer-center, .footer-right {
    flex: 100%;
    margin-bottom: 20px;
  }
}

    

    /* ---> By @lepotoriko <--- */

